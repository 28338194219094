<template>
  <div>
    <el-card v-loading="loading" :body-style="{ padding: '36px' }">
      <div v-if="!bindMsg.IsUse">
        <el-steps class="color-409eff" :space="180" :active="step" finish-status="success" direction="vertical" process-status="success">
          <el-step title="开通视频号小店">
            <div slot="title" style="color:#606266FF" class="font-bold font-16">
              开通视频号小店
            </div>
            <div slot="description" class="flex flex-align-start flex-justify-between" style="width:100%">
              <div class="description flex flex-align-center flex-wrap">
                使用以后要进行视频号卖货（直播卖货、视频号橱窗卖货等场景）的微信扫描开店二维码，根据要求填写开店信息、主体信息等，完成视频号小店开通
                <el-link href="https://developers.weixin.qq.com/doc/channels/Operational_Guidelines/Shop_opening_guidelines.html"
                :underline="false" target="_blank" class="margin-left-10 font-14" style="color:#409eff">查看开店指引</el-link>
              </div>
              <el-button type="primary" @click="open('https://channels.weixin.qq.com/shop')">去开通</el-button>
            </div>
          </el-step>
          <el-step title="开通微信客服">
            <div slot="title" style="color:#606266FF" class="font-bold font-16">
              开通微信客服
            </div>
            <div slot="description" class="flex flex-align-start flex-justify-between">
              <div class="description flex flex-align-center flex-wrap">
                未开通微信客服，小店无法上架商品。用视频号小店超级管理员的企业微信或微信扫码授权即可开通
                <el-link href="https://work.weixin.qq.com/nl/act/p/546743f393c441f3"
                :underline="false" target="_blank" class="margin-left-10 font-14" style="color:#409eff">查看开通说明</el-link>
              </div>
              <el-button type="primary" @click="open('https://channels.weixin.qq.com/shop')">去开通</el-button>
            </div>
          </el-step>
          <el-step title="开放平台绑定视频号小店">
            <div slot="title" style="color:#606266FF" class="font-bold font-16">
              开放平台绑定视频号小店
            </div>
            <div slot="description" class="flex flex-align-start flex-justify-between">
              <div class="description flex flex-align-start flex-wrap">
                <div>
                  <div>登录商城小程序绑定的开放平台，[管理中心]-[视频号小店]，绑定视频号小店；</div>
                  <div style="color:#f56c6c">⚠注意：商城小程序和视频号小店未绑定在同一开放平台下，无法同步分享员信息</div>
                </div>
                <el-link href="https://jusnn6k8al.feishu.cn/docx/OL5fd1ajjobsrqx7ShncMkoknvc?from=from_copylink"
                :underline="false" target="_blank" class="margin-left-10 font-14" style="color:#409eff">查看绑定说明</el-link>
              </div>
              <div>
                <div style="text-align:right" v-if="step<2">
                  <el-button type="text" @click="open('https://open.weixin.qq.com/')">去绑定</el-button>
                </div>
                <div>
                  <el-button type="primary" @click="step=2">已完成绑定</el-button>
                </div>
              </div>
            </div>
          </el-step>
          <el-step title="完成企店商城与视频号小店的绑定">
            <div slot="title" style="color:#606266FF" class="font-bold font-16">
              完成企店商城与视频号小店的绑定
            </div>
            <div slot="description" class="flex flex-align-start flex-justify-between">
              <div class="description flex flex-align-start flex-wrap">
                <div>
                  配置视频号小店相应参数，完成商城与视频号小店绑定，进行数据同步，包含但不限于：分享员数据同步、视频号小店订单同步、视频号商品同步
                </div>
                <el-link href="https://jusnn6k8al.feishu.cn/docx/J2ttdI8tCoap1ZxAsUOcVZ0ZnUb?from=from_copylink"
                :underline="false" target="_blank" class="margin-left-10 font-14" style="color:#409eff">查看绑定说明</el-link>
              </div>
              <el-button :type="step==2?'primary':'info'" :plain="step!=2" @click="nowBind">立即绑定</el-button>
            </div>
          </el-step>
        </el-steps>
      </div>
      <div v-else>
        <div class="flex flex-align-center">
          <div class="font-18 font-bold margin-right-20" style="color:#303133FF">已绑定视频号小店</div>
          <el-button type="text" @click="refreshInfo">刷新</el-button>
          <el-button type="text" @click="closeData">关闭数据打通</el-button>
        </div>
        <div class="">
          <div class="subhead font-16 font-bold margin-top-40">基础信息</div>
          <div class="form">
            <div class="flex flex-align-center margin-top-40">
              <div class="form-label font-bold" style="color:#606266FF">店铺头像</div>
              <div class="margin-left-165">
                <img :src="bindMsg.HeadImgUrl" style="width:50px;height:50px;border-radius:50%" alt="">
              </div>
            </div>
            <div class="flex flex-align-center margin-top-40">
              <div class="form-label font-bold" style="color:#606266FF">店铺名称</div>
              <div class="margin-left-165 font-14" style="color:#606266FF">
                {{bindMsg.Nickname}}
              </div>
            </div>
            <div class="flex flex-align-center margin-top-40">
              <div class="form-label font-bold" style="color:#606266FF">店铺类型</div>
              <div class="margin-left-165 font-14" style="color:#606266FF">
                {{bindMsg.SubjectType}}
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="subhead font-16 font-bold margin-top-40">账号信息</div>
          <div class="form">
            <div class="flex flex-align-center margin-top-40">
              <div class="form-label font-bold" style="color:#606266FF">原始ID</div>
              <div class="margin-left-165 font-14" style="color:#606266FF">
                {{bindMsg.UserName}}
              </div>
            </div>
            <div class="flex flex-align-center margin-top-40">
              <div class="form-label font-bold" style="color:#606266FF">AppId</div>
              <div class="margin-left-165 font-14" style="color:#606266FF">
                {{bindMsg.AppId}}
              </div>
            </div>
            <div class="flex flex-align-center margin-top-40">
              <div class="form-label font-bold" style="color:#606266FF">AppSecret</div>
              <div class="margin-left-165 font-14" style="color:#606266FF">
                {{bindMsg.AppSecret}}
              </div>
            </div>
            <div class="flex flex-align-center margin-top-40">
              <div class="form-label font-bold" style="color:#606266FF">Token令牌</div>
              <div class="margin-left-165 font-14" style="color:#606266FF">
                {{bindMsg.WeiXinChannelsCallbackToken}}
              </div>
            </div>
            <div class="flex flex-align-center margin-top-40">
              <div class="form-label font-bold" style="color:#606266FF">消息密钥</div>
              <div class="margin-left-165 font-14" style="color:#606266FF">
                {{bindMsg.WeiXinChannelsCallbackEncodingAESKey}}
              </div>
            </div>
            <div class="flex flex-align-center margin-top-40">
              <div class="form-label font-bold" style="color:#606266FF">服务器地址</div>
              <div class="margin-left-165 font-14" style="color:#606266FF">
                {{bindMsg.CallBackUrl}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-card>

    <el-dialog :visible.sync="bindShow" title="绑定视频号小店" custom-class="dialog-body-paddingTop-10" @closed="closeDialog" width="650px">
      <div class="font-12 flex" style="color:#999999FF">
        前往视频号小店后台-服务市场-自研，查找并配置下方参数
        <el-link href="https://jusnn6k8al.feishu.cn/docx/J2ttdI8tCoap1ZxAsUOcVZ0ZnUb?from=from_copylink"
        :underline="false" target="_blank" class="margin-left-10 font-12" style="color:#409eff">点击查看指引</el-link>
      </div>
      <div class="form" style="margin-top:20px">
        <div class="flex flex-align-center">
          <div class="form-label label-star">AppId：</div>
          <el-input v-model="bindMsg.AppId" placeholder="复制视频号小店AppId粘贴在此处" maxlength="50"></el-input>
        </div>
        <div class="flex flex-align-center margin-top-20">
          <div class="form-label label-star">AppSecret：</div>
          <el-input v-model="bindMsg.AppSecret" placeholder="复制视频号小店AppSecret粘贴在此处" maxlength="50"></el-input>
        </div>
        <div class="flex flex-align-center margin-top-20">
          <div class="form-label">Token：</div>
          <el-input v-model="bindMsg.WeiXinChannelsCallbackToken" :disabled="true">
            <template slot="append">
              <el-button v-clipboard:copy="bindMsg.WeiXinChannelsCallbackToken" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
            </template>
          </el-input>
        </div>
        <div class="flex flex-align-center margin-top-20">
          <div class="form-label">消息密钥：</div>
          <el-input v-model="bindMsg.WeiXinChannelsCallbackEncodingAESKey" :disabled="true">
            <template slot="append">
              <el-button v-clipboard:copy="bindMsg.WeiXinChannelsCallbackEncodingAESKey" v-clipboard:success="onCopy" v-clipboard:error="onError">复制</el-button>
            </template>
          </el-input>
        </div>
        <div class="flex flex-align-center margin-top-20">
          <div class="form-label">服务器地址：</div>
          <el-input :value="bindMsg.CallBackUrl+bindMsg.AppId" :disabled="true">
            <template slot="append">
              <el-button @click="beforeCopy">复制</el-button>
            </template>
          </el-input>
        </div>
      </div>
      <div class="flex flex-align-center margin-top-20">
        <el-button @click="bindShow=false" style="margin-left:auto">取消</el-button>
        <el-button type="primary" :loading="bindloading" @click="confirmBind">{{bindloading?'小店绑定中':'保存'}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  channelShopchannelShopInfo,
  channelShopchannelShopRefresh,
  channelShopchannelShopConfigClose,
  channelShopchannelShopConfigSave,
  channelShopchannelShopBasicsInfoByAppId
} from "@/api/goods"
export default {
  data () {
    return {
      loading:false,
      shopId:0,
      step:1,
      bindShow:false,
      bindMsg:{
        AppId:'',
        AppSecret:'',
        WeiXinChannelsCallbackToken:'',
        WeiXinChannelsCallbackEncodingAESKey:'',
        CallBackUrl:'',
        Nickname:'',
        HeadImgUrl:'',
        SubjectType:'',
        UserName:'',
        IsUse:false,
      },
      bindloading:false
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    beforeCopy(){
      let that = this
      if(!this.bindMsg.AppId){
        this.$message.error('请输入AppID')
        return
      }
      this.$copyText(this.bindMsg.CallBackUrl+this.bindMsg.AppId).then(()=>{
        this.onCopy()
      }).catch(()=>{
        this.onError()
      })
    },
    async refreshInfo(){
      try{
        this.loading = true
        let res = await channelShopchannelShopRefresh()
        if(res.IsSuccess){
          this.getInfo()
        }
      }finally{
        this.loading = false
      }
    },
    closeData(){
      this.$confirm('关闭后，视频号数据将无法同步至商城，包含但不限于视频号订单、视频号商品、视频号分享员、分享员卖货', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        try{
          this.loading = true
          let res = await channelShopchannelShopConfigClose()
          if(res.IsSuccess){
            this.$message.success('已关闭商城与视频号小店数据打通')
            this.getInfo()
          }
        }finally{
          this.loading = false
        }
      })
    },
    async getInfo(){
      try{
        this.loading = true
        let res = await channelShopchannelShopInfo()
        if(res.IsSuccess){
          this.bindMsg = res.Result
        }
      }finally{
        this.loading = false
      }
    },
    closeDialog(){
      // this.bindMsg.AppId = ''
      // this.bindMsg.AppSecret = ''
      // this.bindMsg = {
      //   AppId:'',
      //   AppSecret:'',
      //   WeiXinChannelsCallbackToken:'',
      //   WeiXinChannelsCallbackEncodingAESKey:'',
      //   CallBackUrl:'',

      //   Nickname:'',
      //   HeadImgUrl:'',
      //   SubjectType:'',
      //   UserName:'',
      //   IsUse:false,
      // }
    },
    async confirmBind(){
      if(!this.bindMsg.AppId){
        this.$message.error('请输入AppID')
        return
      }
      if(!this.bindMsg.AppSecret){
        this.$message.error('请输入AppSecret')
        return
      }
      try{
        this.bindloading = true
        let data = {
          AppId:this.bindMsg.AppId,
          AppSecret:this.bindMsg.AppSecret,
        }
        let res = await channelShopchannelShopBasicsInfoByAppId(data)
        if(res.IsSuccess){
          this.$confirm(`是否确认绑定视频号小店：${res.Result.Nickname}？`, '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '关闭',
            type: 'warning'
          }).then(async () => {
            try{
              let res = await channelShopchannelShopConfigSave(data)
              if(res.IsSuccess){
                this.bindShow = false
                this.$message.success('操作成功')
                this.getInfo()
              }
            }finally{
              //
            }
          })
        }
      }finally{
        this.bindloading = false
      }
    },
    open(url){
      window.open(url)
    },
    nowBind(){
      if(this.step<2){
        this.$message.error('请先完成步骤3')
        return
      }
      this.bindMsg.AppId = ''
      this.bindMsg.AppSecret = ''
      this.bindShow = true
    },
    onCopy() {
      this.$message({
        message: "复制成功！",
        type: 'success'
      });

    },
    onError() {
      this.$message({
        message: "复制失败！",
        type: 'error'
      });
    },
  }
}
</script>

<style lang='less' scoped>
.margin-left-165{
  margin-left: 165px;
}
.subhead{
  color: #303133FF;
  &::before{
    content: '丨';
    color: #409EFF;
    font-size: 12px;
    font-weight: bold;
    vertical-align: middle;
  }
}
.color-409eff{
  ::v-deep .el-step__head.is-success{
    color: #409eff;
    border-color: #409eff;
  }
  ::v-deep .el-step__title.is-success{
    color:#409eff
  }
  ::v-deep .is-success{
    .el-step__icon{
      background: #ECF5FF;
    }
  }
  .description{
    font-size: 14px;
    color: #909399;
    line-height: 1.5;
  }
  ::v-deep .el-step__description{
    padding-right:3%
  }
  // ::v-deep .el-step:last-of-type .el-step__description, .el-step:last-of-type .el-step__main{
  //   padding-right:10%
  // }
}
</style>